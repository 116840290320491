<i18n>
ru:
  address: 'Адрес: '
  count: '{num} шт'
  created: Создана
  delivery: Доставка
  deliveryCost: Доставка
  discount: Скидка
  deliveryASAP: как можно скорее
  inHall: В зале
  order: 'Заказ:'
  orderAmount: 'Сумма заказа:'
  orderAmountSum: Общая сумма заказа
  orderAmountTotal: Итого
  orderContents: 'Состав заказа:'
  orderNum: 'Заказ №{num}'
  pointsAccrue: Баллов к начислению
  pointsWriteOff: Баллов к списанию
  repeat: Повторить заказ
  selfService: Самовывоз
  status: 'Статус: '
  serviceFee: 'Сервисный сбор:'
  priorityCooking: Приоритетное приготовление
  tax: Налог
  tip: Чаевые
ua:
  address: 'Адреса: '
  count: '{num} шт'
  created: Створено
  delivery: Доставка
  deliveryCost: Доставка
  discount: Знижка
  deliveryASAP: якомога швидше
  inHall: В залі
  order: 'Замовлення:'
  orderAmount: 'Сума замовлення:'
  orderAmountSum: Загальна сума замовлення
  orderAmountTotal: Всього
  orderContents: 'Склад замовлення:'
  orderNum: 'Замовлення №{num}'
  pointsAccrue: Балів до нарахування
  pointsWriteOff: Балів до списання
  repeat: Повторити замовлення
  selfService: Самовивіз
  status: 'Статус: '
  serviceFee: 'Збір послуг:'
  priorityCooking: 'Пріоритетне приготування:'
  tax: Податок
  tip: Чайові
us:
  address: 'Address: '
  count: '{num} item(s)'
  created: Created
  delivery: Delivery
  deliveryCost: Delivery
  discount: Discount
  deliveryASAP: as soon as possible
  inHall: In restaurant hall
  order: 'Order:'
  orderAmount: 'Order amount:'
  orderAmountSum: Total order amount
  orderAmountTotal: Total
  orderContents: 'Order contents:'
  orderNum: 'Order #{num}'
  pointsAccrue: Points to accrue
  pointsWriteOff: Points to write-off
  repeat: Repeat order
  selfService: Self-service
  status: 'Status: '
  serviceFee: 'Service collection:'
  priorityCooking: 'Priority сooking:'
  tax: Tax
  tip: Tip
</i18n>

<template>
  <div class="v-account-order-info">
    <div
      class="v-popup-head v-title v-account-order-info__title v-mb-sm v-font-weight-600"
      v-html="
        order.Number && order.Number !== '0'
          ? translate('orderDetailsPopup.orderNum', { num: order.Number })
          : translate('orderDetailsPopup.order')
      "
    />
    <div class="v-account-order-info__subtitle v-font-weight-600 v-mb-xs">
      <span
        v-if="order.OrderType === OrderType.CourierDelivery"
        class="v-mr-xs"
        v-html="translate('orderDetailsPopup.delivery')"
      />
      <span
        v-else-if="order.OrderType === OrderType.NoShipment"
        class="v-mr-xs"
        v-html="translate('orderDetailsPopup.selfService')"
      />
      <span
        v-else-if="order.OrderType === OrderType.InHall"
        class="v-mr-xs"
        v-html="translate('orderDetailsPopup.inHall')"
      />
      <span
        v-if="order.OrderType === OrderType.InHall"
        v-html="`${dateTime(order.Created * 1000, 'orderDate')} ${orderYear}`"
      />
      <span v-else-if="order.DeliveryTime.RightNow">
        <span v-html="`${dateTime(order.Created * 1000, 'orderDate')} ${orderYear}, `" />
        <span v-html="translate('orderDetailsPopup.deliveryASAP')" />
      </span>
      <span v-else>
        <span v-html="`${dateTime(order.DeliveryTime.UTCTime * 1000, 'orderDate')} ${orderYear}, `" />
        <span
          v-if="order.DeliveryTime.UTCTime === order.DeliveryTime.UTCTimeRangeEnd"
          v-html="dateTime(order.DeliveryTime.UTCTime * 1000, 'time')"
        />
        <span
          v-else
          v-html="
            translate('deliveryTimeRathloriel.minutesPeriodText', {
              start: dateTime(order.DeliveryTime.UTCTime * 1000, 'time'),
              end: dateTime(order.DeliveryTime.UTCTimeRangeEnd * 1000, 'time')
            })
          "
        />
      </span>
    </div>
    <div class="v-body-text-color-light v-mb-md">
      <span
        class="v-mr-xs"
        v-html="translate('orderDetailsPopup.created')"
      />
      <span v-html="dateTime(order.Created * 1000, 'short')" />
    </div>
    <div class="v-account-order-info__upper_details v-row v-mb-sm">
      <div
        class="v-body-text-color-light v-col-3 v-col-sm-2 v-mb-xs"
        v-html="translate('orderDetailsPopup.address')"
      />
      <div class="v-col-9 v-col-sm-10 v-account-order-info__upper_details-value">
        <address-delivery
          v-if="order.OrderType === OrderType.CourierDelivery"
          :address="order.Address"
        />
        <address-from-terminal
          v-else
          :terminal-id="order.TargetTerminalID"
        />
      </div>
      <template v-if="order.Number && order.Number !== '0'">
        <div
          class="v-body-text-color-light v-col-3 v-col-sm-2 v-mb-xs"
          v-html="translate('orderDetailsPopup.order')"
        />
        <div
          class="v-col-9 v-col-sm-10 v-account-order-info__upper_details-value"
          v-html="translate('ordersHistory.orderNum', { num: order.Number })"
        />
      </template>
      <div
        class="v-body-text-color-light v-col-3 v-col-sm-2 v-mb-xs"
        v-html="translate('orderDetailsPopup.status')"
      />
      <div class="v-col-9 v-col-sm-10 v-font-weight-600 v-account-order-info__upper_details-value">
        <common-order-status
          :show-colors="false"
          :status="order"
        />
      </div>
    </div>
    <div class="v-account-order-info__order-contents v-mb-sm">
      <div
        class="v-account-order-info__order-contents-title v-font-weight-600"
        v-html="translate('orderDetailsPopup.orderContents')"
      />
      <div
        v-for="(item, index) in order.Items"
        :key="`${item.ProductID}-${item.OptionID}`"
        class="v-account-order-info-item v-d-flex"
        :class="{ 'v-mb-xs': index < order.Items.length - 1 }"
      >
        <div class="v-col-3">
          <arora-nuxt-image
            :alt="item.Name"
            :image="item.SmallImage"
            image-type="ProductSmall"
          />
        </div>
        <div class="v-w-100 v-d-flex v-flex-column">
          <div
            class="v-account-order-info-item__title v-mb-xs"
            v-html="item.Name"
          />
          <div
            v-if="item.OptionName"
            class="v-account-order-info-item__option-name"
            v-html="sanitize(item.OptionName)"
          />
          <div
            v-if="appConfig.VueSettingsPreRun.OrderCompleteShowProductDesc && item.Description"
            class="v-account-order-info-item__subtitle"
            v-html="sanitize(item.Description)"
          />
          <div
            v-if="item.HasModifiers"
            class="v-account-order-info-item__subtitle"
          >
            <div
              v-if="Object.entries(item.ModifiersNames).length > 0"
              class="v-mb-sm"
            >
              <span
                v-for="(name, modIndex) in item.ModifiersNames"
                :key="`mod-${modIndex}`"
                class="v-mr-xs"
                v-html="
                  translate('orderCompleteItems.modString', {
                    count: item.Modifiers[modIndex],
                    mod: name
                  })
                "
              />
            </div>
            <div v-if="Object.entries(item.RemovableIngredientNames).length > 0">
              <span v-html="translate('itemsPage.removableIngredientsPrefix')" />
              <span
                v-for="([modID, modName], modIndex) in Object.entries(item.RemovableIngredientNames)"
                :key="`mod-${sanitize(modID)}`"
              >
                <span
                  class="v-account-order-info-item__ingredient"
                  v-html="clean(modName)"
                />
                <span
                  v-if="modIndex !== Object.entries(item.RemovableIngredientNames).length - 1"
                  v-html="'; '"
                />
              </span>
            </div>
          </div>

          <div
            class="v-d-flex v-justify-content-between v-align-items-center v-account-order-info-item__prices-total"
          >
            <div class="v-d-flex v-flex-row-no-wrap v-account-order-info-item__prices">
              <div class="v-mr-xs">
                <common-currency
                  v-if="item.PriceInPoints > 0"
                  show-points
                  :amount="item.PriceInPoints"
                />
                <common-currency
                  v-else
                  :amount="item.PriceFullModified"
                />
              </div>
              <common-currency
                v-if="item.PriceFullModified !== item.PriceFull"
                strikethrough
                :amount="item.PriceFull"
              />
            </div>
            <div
              class="v-account-order-info-item__amount"
              v-html="translate('orderDetailsPopup.count', { num: item.Count })"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="v-account-order-info__lower_details v-mb-sm">
      <div
        class="v-w-100 v-account-order-info__lower_details-title"
        v-html="translate('orderDetailsPopup.orderAmount')"
      />
      <div class="v-w-100 v-d-flex v-justify-content-between v-body-text-color-light v-mb-xs">
        <span v-html="translate('orderDetailsPopup.orderAmountSum')" />

        <common-currency :amount="order.Sum" />
      </div>
      <div class="v-w-100 v-d-flex v-justify-content-between v-body-text-color-light v-mb-xs">
        <span v-html="translate('orderDetailsPopup.discount')" />

        <common-currency :amount="order.Sum - order.SumWithDiscount" />
      </div>
      <div
        v-if="order.Tax > 0"
        class="v-w-100 v-d-flex v-justify-content-between v-body-text-color-light v-mb-xs"
      >
        <span v-html="translate('orderDetailsPopup.tax')" />

        <common-currency :amount="order.Tax" />
      </div>
      <div
        v-if="order.Gratuity > 0"
        class="v-w-100 v-d-flex v-justify-content-between v-body-text-color-light v-mb-xs"
      >
        <span v-html="translate('orderDetailsPopup.tip')" />

        <common-currency :amount="order.Gratuity" />
      </div>
      <div
        v-for="pointsChange in order.Points"
        :key="`order-details-points-type-${pointsChange.Accrual ? 'accrual' : 'write-off'}`"
        class="v-w-100 v-d-flex v-justify-content-between v-body-text-color-light v-mb-xs"
      >
        <template v-if="pointsChange.Type === PointsType.PointsCommon">
          <span
            v-html="
              translate(
                pointsChange.Accrual
                  ? 'orderDetailsPopup.pointsAccrue'
                  : 'orderDetailsPopup.pointsWriteOff'
              )
            "
          />

          <div
            class="v-d-flex v-flex-row-no-wrap"
            :class="[pointsChange.Accrual ? 'v-success-color' : 'v-error-color']"
          >
            <span
              class="v-mr-xxs"
              v-html="pointsChange.Accrual ? '+' : '-'"
            />
            <common-currency
              show-points
              :amount="pointsChange.Sum"
            />
          </div>
        </template>
      </div>
      <div class="v-w-100 v-d-flex v-justify-content-between v-body-text-color-light v-mb-xs">
        <span v-html="translate('orderDetailsPopup.serviceFee')" />

        <common-currency :amount="order.ServiceFeeCost" />
      </div>
      <div
        v-if="order.PriorityServiceCost > 0"
        class="v-w-100 v-d-flex v-justify-content-between v-body-text-color-light v-mb-xs"
      >
        <span v-html="translate('orderDetailsPopup.priorityCooking')" />

        <common-currency :amount="order.PriorityServiceCost" />
      </div>

      <div
        class="v-w-100 v-d-flex v-justify-content-between v-body-text-color-light v-penultimate-detail"
      >
        <span v-html="translate('orderDetailsPopup.deliveryCost')" />

        <common-currency :amount="order.DeliverySum" />
      </div>
      <div class="v-w-100 v-d-flex v-justify-content-between v-font-weight-600 v-last-detail">
        <span v-html="translate('orderDetailsPopup.orderAmountTotal')" />

        <common-currency :amount="order.SumTotal" />
      </div>
    </div>
    <div class="v-account-order-info__button">
      <arora-button
        :label="translate('orderDetailsPopup.repeat')"
        @click="showRepeatPopup"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { OrderType, PointsType } from '~api/consts'

import type { OrderInfo } from '~types/clientStore'

const { order } = defineProps<{
  order: OrderInfo
}>()

const appConfig = useAppConfig()
const { sanitize, translate, dateTime, clean } = useI18nSanitized()
const { fromMillisInZone } = useDateTime()

const popupStore = usePopupStore()

const orderYear = computed<string>(() => {
  const baseDate = (order.DeliveryTime.RightNow ? order.Created : order.DeliveryTime.UTCTime) * 1000
  return fromMillisInZone(baseDate, appConfig.RestaurantSettingsPreRun.GMT).toLocaleString({
    year: 'numeric'
  })
})

function showRepeatPopup(): void {
  popupStore.closePopup().then(() =>
    popupStore.openPopup({
      popupName: 'accountOrderRepeatPopup',
      popupClosable: true,
      popupProperties: new Map<string, unknown>([['order', order]])
    })
  )
}
</script>

<style lang="scss">
@use '~/assets/variables';

.v-account-order-info {
  &__title {
    font-size: variables.$TextSizeH3;
  }

  &__subtitle {
    font-size: 1.1rem;
  }

  &__order-contents {
    background: variables.$PopupBackgroundDarken;
    border-radius: max(6px, calc(variables.$BorderRadius - 6px));
    padding: 12px;

    .v-account-order-info__order-contents-title {
      font-size: 1.15rem;
      margin-bottom: 12px;
    }
  }

  &__upper_details {
    &-value {
      padding-left: 0;
    }
  }

  &__lower_details {
    .v-account-order-info__lower_details-title {
      font-size: 1.1rem;
      margin-bottom: 12px;
    }

    .v-penultimate-detail {
      padding-bottom: 8px;
      border-bottom: 1px solid variables.$BorderColor;
    }

    .v-last-detail {
      padding: 12px 0;
      font-size: 1.15rem;
    }
  }

  &__button {
    text-align: right;
  }
}

.v-account-order-info-item {
  background: variables.$BodyElementsBackground;
  color: variables.$BodyTextColor;
  box-shadow: variables.$CardShadow;
  padding: 12px 16px;
  border-radius: variables.$BorderRadius;
  gap: 16px;

  &__title {
    font-size: 1.1rem;
  }
  &__option-name {
    font-size: 0.9rem;
    opacity: 0.5;
  }
  &__subtitle {
    color: variables.$BodyTextColorLight;
  }

  &__ingredient {
    text-decoration: line-through;
  }

  &__prices-total {
    margin-top: auto;
  }

  &__prices {
    font-size: 1.15rem;
    max-height: 1.15rem;
  }

  &__amount {
    background: variables.$SecondaryBackgroundColor;
    color: variables.$SecondaryColor;
    padding: 5px 10px;
    border-radius: variables.$BorderRadiusInput;
    font-size: 1.1rem;
    font-weight: 500;

    pointer-events: none;
  }
}
</style>
